import ProfileCard from "./profile-card/profileCard";
import ParticlesComponent from "./components/particles";
function App() {
  return (
    <div className="App">
      <ParticlesComponent id="particles" />
      <ProfileCard />
    </div>
  );
}

export default App;
