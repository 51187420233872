import React from "react";
import "../App.css";
import instagramLogo from "../assets/social-media/instagram.svg";
import linkedinLogo from "../assets/social-media/linkedin.svg";
import xLogo from "../assets/social-media/x.svg";
import threadsLogo from "../assets/social-media/threads.svg";
import profilePhoto from "../assets/profile-photo/profile-photo.jpeg";
const ProfileCard = () => {
  return (
    <div className="container">
      <div className="profile-wrapper">
        <div className="profile">
          <div className="profile-image">
            <img src={profilePhoto} alt="Profile" />
          </div>
          <ul className="social-icons">
            <li>
              <a
                href="https://www.instagram.com/yuusufcakmak?igsh=cXZzZmM4ZXl4ZzZo&utm_source=qr"
                title="Instagram"
              >
                <img src={instagramLogo} alt="Instagram" />
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/in/myusufcakmak/"
                title="Linkedln"
              >
                <img src={linkedinLogo} alt="Linkedln" />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/yuusufcakmak" title="x">
                <img src={xLogo} alt="x" />
              </a>
            </li>
            <li>
              <a href="https://www.threads.net/@yuusufcakmak" title="Threads">
                <img src={threadsLogo} alt="Threads" />
              </a>
            </li>
          </ul>
          <div className="profile-name">
            <h2>Yusuf Çakmak</h2>
            <div className="profile-bio">
              Yukarıdaki bağlantılardan bana ulaşabilirsin.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
